/*******************************
         Site Overrides
*******************************/



.ui.multiple.dropdown {
    &> .label {
        position: relative;
        text-align: left;
        font: Regular 14px/17px "Open Sans" !important;
        letter-spacing: 0;
        color: #4D4D4DBE;
        opacity: 1;
        background: #58C5C860 0% 0% no-repeat padding-box;
        border: 1px solid #5A849C48;
        border-radius: 4px;
        width: 200px;
        height: 34px;
        line-height: 34px;
        padding: 0 !important;

        span {
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            font-family: "Open Sans", sans-serif !important;
            display: inline-block;
            max-width: 155px;
            padding: 0 0.6em !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis;

        }
        .delete.icon {
            position: absolute;
            right: 0.6em;
        }
    }
}


@media only screen and (min-width: 1200px) {
    .ui.modal {
        width: 1200px;
    }
}


.overflow-hidden {
    overflow: hidden;
}

.clickable {
    cursor:pointer;
}


.t-center {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.t-center-h {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
}

.t-center-v {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
}


.no-padding {
    padding: 0 !important;
}

a {
    color: #00AEEF;
    text-decoration: none;
}

a:hover {
    color: #043962;
    text-decoration: none;
}

.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}


.ui.divider:not(.vertical):not(.horizontal) {
    border-bottom: none;
    border-top: 1px solid #EFEFEF;
}




*:focus {
    outline: none;
}

body {
    font-size: 16px;
    font-family: "Open Sans", Content-font, sans-serif;
    font-weight: 400;
    line-height: 1.625;
    color: #4D4D4D;
}

.logo-space {
    font-family: "Roboto Light", serif;
    font-size: 24px;
    margin: 0 auto;
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        font-size: 14px;
    }
}


.login-form {}
.login-form .button {
    font: Bold 20px/27px Open Sans;
    letter-spacing: 0;
    height: 50px;
    padding: 0;
}

button.ui.button.file-upload .icon,
.ui[class*="right labeled"].icon.button,
.ui[class*="right labeled"].icon.button > .icon,
button.ui.icon.button,
button.ui.button.file-upload:hover,
button.ui.button.file-upload:active,
button.ui.button.file-upload {
    border: none;
    box-shadow: none;
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
    min-width: initial;
    text-align: center;
    padding: 0 !important;
    margin: 0 auto;
    position: relative !important;
    float: none;
    outline: 0;
    background-color: transparent !important;
}


/*
 * hacking datepicker, hope it not infecting any other widget
 * see: https://github.com/arfedulov/semantic-ui-calendar-react/issues/184
 * TODO: if the PR accepted remove this shit from here.
 */
.ui.bottom.left.flowing.popup.transition.visible:before {
    background-color: #043962;
}

.ui .form .field:last-child {
    margin-bottom: 10px;
}


@media print {

    .admin-layout > .ui.grid,
    .admin-layout .side,
    .admin-layout .menu { visibility: hidden !important; display: none !important; }
    .admin-layout .main-content,
    .admin-layout .content { min-width: 100%; margin: 0; }
    .admin-layout .content .segment { box-shadow: none; }


    .print-page-break,
    .print-page {
        page-break-after: always;
        min-height: 2em;
    }

}

.hide-errors .error {
    display: none;
}



.password-strength-meter {
    text-align: left;
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 8px;
    max-width: 100%;
}

.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
    background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
    background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
    background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
    background-color: #70C1B3;
}
