/*******************************
         Site Overrides
*******************************/


table {
    max-width: 100%;
    overflow-x: auto;

    .bg-white {
        background: white !important;
    }

    .bg-gray {
        background: rgba(0, 0, 50, 0.02) !important;
    }

}

.ui.table {
    max-width: 100%;
}
