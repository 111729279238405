/*******************************
         Site Overrides
*******************************/


.ui.segment {
 
    border-radius: 15px;
    border: none;
}

.ui.segment.with-header {

    padding: 0;

    &> .header {
        border-bottom: 1px solid #f2f2f2;
        font: Bold 22px/14px "Open Sans";
        padding: 2.2em 2.2em 2.1em 2.2em;
        margin-bottom: 0;
    }

    &> .header.medium {
        border-bottom: 1px solid #f2f2f2;
        font: Bold 18px/11px "Open Sans";
        padding: 2.2em 2.6em 2.1em 2.4em;
        margin-bottom: 0;
    }

    .ui.basic.segment {
        padding: 0 2.8em 2em 3em;
        margin-top: 26px;
    }
}
