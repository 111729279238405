/*******************************
         Site Overrides
*******************************/

.ui {
    .grid {
        //max-width: 100%;
    }
}

.ui.grid > .column:not(.row), .ui.grid > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem;
}

.ui.grid > .row {
    padding-bottom: 0;
}


.ui.grid > .row.button-group {
    padding-left: 0;
    padding-right: 0;
    margin-top: 1em;
    .column {
        padding-left: 14px;
        padding-right: 14px;
    }
    .button {
        margin-right: 1.4em;
    }
    .button:last-child {
        margin-right: 0;
    }
}
