/*******************************
         Site Overrides
*******************************/


.ui.search {

  .results {
    max-height: 252px;
    overflow: hidden;
    overflow-y: auto;
  }
}
