/*******************************
         Site Overrides
*******************************/

.ui {
    .form {
        .field {
            position: relative;
            margin-top: 8px;
            margin-bottom: 8px;
            &.error {
                .error.message {
                    display: block;
                    box-shadow: none;
                    padding: 0.4em 0.8em;
                    border-radius: 0;
                    margin-top: -12px;
                    margin-bottom: 0;
                    background: none;
                }
            }

            input {
                transition: 0.3s background ease-in-out;
                line-height: 42px;

                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #5A849C48;
                border-radius: 6px;
                opacity: 1;
            }

            .selection.dropdown {
                min-height: 1.6em;
                line-height: 1.6em;
                .dropdown.icon {
                    line-height: 1.6em;
                }
                &.multiple {
                    min-height: 3em;
                    line-height: 2em;
                    .default.text {
                        line-height: 2em;
                        padding-top: 8px;
                    }
                }
            }

            .multiple.dropdown {
                padding: 0.2em;
            }

            .disabled {
                > input {
                    background: #5A849C2F 0% 0% no-repeat padding-box;
                    box-shadow: 0 0 0 #00000024;
                    border: 0.699999988079071px solid #043962;
                    border-radius: 6px;
                    opacity: 0.5;
                    color: #74747490;
                    margin: 0 0 0.38rem 0;
                }
            }

        }

        .field > label,
        label {
            font: Bold 14px/22px Open Sans;
            margin: 0em 0em 0.4rem 0em;
        }

        .ui.checkbox,
        .ui.radio,
        .ui.segment.scrollable {
            margin-top: 4px;
            label {
                font-weight: normal;
                line-height: 1.2em;
            }
        }


        .field.error+.error,
        .field.error+.message {
            display: block;
            box-shadow: none;
            padding: 0.4em;
            border-radius: 0;
            margin-top: -12px;
            margin-bottom: 0;
            background: none;
        }

        .field:last-child {
            &.error:last-child {
                .error.message:last-child{
                    margin-top: 0;
                }
            }
        }

        input {
            padding-left: 16px;
            padding-right: 16px;
            line-height: 3em;
        }
    }

    .recruitment-form {
        .field > label,
            label {
                position: absolute;
                bottom: 100%;
            }
    }

    .multiple.search.dropdown > input.search {
        line-height: 1.6em;
    }

}
.recruitment-grid {
    margin-top: 3.8em
}

.ui.form .disabled.fields .field, .ui.form .disabled.field, .ui.form .field :disabled {
    background-color: rgba(225, 225, 225, 0.111);
}


.ui.multiple.search.dropdown {
    & > input.search {
        line-height: 1.6em !important;
        padding: 0.3em 0 !important;
        margin: 0 1em !important;
    }
    & > .text {
        line-height: 2.6em !important;
    }
}

.ui.selection.active.dropdown .menu {
    box-sizing: border-box;
}