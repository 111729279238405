/*******************************
         Site Overrides
*******************************/

.field .ui.floating.label {
    position: absolute;
    z-index: 10;
    top: -1em;
    left: 100%;
    margin: 0em 0em 0em -98% !important;
}

.ui.floating.label {
    z-index: 10;
}


