/*******************************
         Site Overrides
*******************************/

.ui.button,
button {
    min-width: 10em;
    min-width: 114px;
    min-height: 46px;
    padding: 0.565em 1.25em 0.565em;
}


.ui.primary.button:hover {
    background-color: #043962;
}


.button.dark-orange {
    font-weight: 600;
    background: #f37032 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 20px #00000024;
    border-radius: 29px;
    opacity: 1;
    color: #fff;
}

.button.dark-orange:hover {
    background-color: darken(#E06542, 5%);
    color: #fff;
}
