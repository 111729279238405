/*******************************
         Site Overrides
*******************************/


.ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless) > .container > .item:not(.right):not(.borderless):first-child  {
    border: none;
}

.ui {
    .menu {
        .item {
            &.logo-space-menu-item {
                padding-left: 0 !important;
                padding-right: 0 !important;

                width: 10em;

                :before {
                    background: none !important;
                }
            }
        }
        .item:last-child {
            padding-right: 0;
        }
    }
}

